.dcl-services-child {
  height: 70%;
  div {
    height: 92%;
  }
}

.dcl-carouseel-btn {
  cursor: pointer;
}
.dcl-services-container {
  min-height: 60rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  .dcl-services-child:nth-child(2) {
    align-self: center;
  }
  .dcl-services-child:nth-child(3) {
    align-self: end;
  }
}
.dcl-services-container-reversed {
  min-height: 60rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  .dcl-services-child:nth-child(2) {
    align-self: center;
  }
  .dcl-services-child:nth-child(1) {
    align-self: end;
  }
}

.tablet-services .dcl-services-container {
  min-height: 52rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  .dcl-services-child:nth-child(2) {
    align-self: end;
  }
}

.mobile-services .dcl-services-container {
  min-height: 52rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  padding: 1rem;

  .dcl-services-child {
    height: 100%;
  }
  // .dcl-services-child:nth-child(2) {
  //   align-self: end;
  // }
}

// @media only screen and (max-width: 768px) {
//   .dcl-services-container {
//     min-height: 95rem;
//     grid-template-columns: repeat(2, 1fr);
//     .dcl-services-child:nth-child(2) {
//       align-self: end;
//     }
//     .dcl-services-child:nth-child(3) {
//       align-self: auto;
//     }
//   }
//   .dcl-services-container-reversed {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
