#logo-img {
  height: 60px;
}
#lottie-close > div {
  margin: 0 !important;
}
#menu-container {
  min-height: 65vh;
}
.cursor-pointer {
  cursor: pointer;
}
#menu-content {
  position: fixed;

  z-index: 9999;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background: black;

  a {
    text-decoration: none;
    color: white;
    font-size: 1.8rem;
    transition-timing-function: linear;
    transition-duration: 1s;
  }
  a:hover {
    transform: scale(1.3);
  }
}

@media only screen and (max-width: 768px) {
  #logo-img {
    height: 45px;
  }
}
@media only screen and (max-width: 576px) {
  #logo-img {
    height: 40px;
  }
}
