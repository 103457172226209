.dcl-contact {
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    border: none;
  }
  input.form-control {
    height: 50px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: white !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer */
    color: white !important;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
}
.dcl-icon {
  font-size: 1.3rem;
}

.btn.btn-dark:hover {
  color: black !important;
  background-color: white !important;
}
